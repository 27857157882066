import React from 'react'
import Layout from '../containers/layout'
import Container from '../components/container'
import Calendar from '../components/pages/calendar'
import SEO from '../components/seo'
import Breadcrumb from '../components/layout/breadcrumb'

const CalendarPage = props => {
  let navItemStack = [{ name: 'تقويم الجمعية', slug: 'calendar' }]

  return (
    <Layout>
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        <Calendar />
      </Container>
    </Layout>
  )
}
export default CalendarPage

export const Head = ({ location }) => (
  <SEO title="تقويم الجمعية" pathname={location.pathname}></SEO>
)
